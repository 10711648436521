// About.js
import React, { useState } from 'react';
import { Target, Laptop, Users, AlertCircle } from 'lucide-react';
import './About.css';

const Feature = ({ title, description, icon: Icon, color }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div 
      className={`feature-card ${color}`}
      onMouseEnter={() => setIsFlipped(true)}
      onMouseLeave={() => setIsFlipped(false)}
    >
      <div className={`card-inner ${isFlipped ? 'flipped' : ''}`}>
        <div className="card-front">
          <Icon size={60} />
          <h3>{title}</h3>
        </div>
        <div className="card-back">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

const Features = () => (
  <div className="features-container">
    <h2 className="about-title" style={{ color: '#005C53' }}>About Us</h2>

    <div className="features-grid">
      <Feature
        title="Our Mission"
        description="We are dedicated to empowering individuals with essential skills for the modern workforce. Our mission is to provide top-notch training and placement services that help non-IT professionals succeed in their careers."
        icon={Target}
        color="bg-yellow-100"
      />
      <Feature
        title="Our Expertise"
        description="Our programs cover a wide range of non-IT fields, including business administration, healthcare, and project management. Our team brings extensive knowledge and practical experience to ensure you gain valuable skills."
        icon={Laptop}
        color="bg-green-100"
      />
      <Feature
        title="Our Clients"
        description="We work with a diverse group of clients, from individuals seeking career advancement to organizations looking to upskill their workforce. Our clients come from various sectors including education, healthcare, and business."
        icon={Users}
        color="bg-blue-100"
      />
      <Feature
        title="Discover More"
        description="Learn more about our training programs, success stories, and how we can help you achieve your career goals. Explore our additional resources and services tailored to your needs."
        icon={AlertCircle}
        color="bg-purple-100"
      />
    </div>
  </div>
);

export default Features;
