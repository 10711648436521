import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const courses = [
  {
    id: 1,
    title: "Tele Callers",
    description: "Master the art of effective communication over the phone.",
    emoji: "📞",
    color: "bg-blue-100"
  },
  {
    id: 2,
    title: "Customer Service",
    description: "Learn to provide exceptional customer support and satisfaction.",
    emoji: "😊",
    color: "bg-green-100"
  },
  {
    id: 3,
    title: "Customer Desk Support",
    description: "Develop skills to handle customer inquiries and provide solutions.",
    emoji: "💼",
    color: "bg-yellow-100"
  },
  {
    id: 4,
    title: "Voice Support",
    description: "Enhance your vocal skills for professional customer interactions.",
    emoji: "🎙️",
    color: "bg-purple-100"
  },
  {
    id: 5,
    title: "Sales & Services",
    description: "Learn effective sales techniques and service provision.",
    emoji: "💰",
    color: "bg-red-100"
  },
  {
    id: 6,
    title: "Desktop Admins",
    description: "Master the essentials of desktop administration and support.",
    emoji: "💻",
    color: "bg-pink-100"
  },
  {
    id: 7,
    title: "Tele Performers",
    description: "Develop skills for engaging and effective telephone communications.",
    emoji: "🎭",
    color: "bg-indigo-100"
  }
];

const CourseCard = ({ title, description, emoji, color }) => {
  const navigate = useNavigate();

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    },
    hover: { 
      scale: 1.05,
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10
      }
    }
  };

  const emojiVariants = {
    hover: { scale: 1.2, rotate: 10 }
  };

  const buttonVariants = {
    hover: { 
      scale: 1.05,
      backgroundColor: "#4338ca",
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10
      }
    }
  };

  const handleReadMore = () => {
    navigate(`/about`);
  };

  return (
    <motion.div
      className={`w-full max-w-sm mx-auto overflow-hidden rounded-lg shadow-lg ${color}`}
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      whileHover="hover"
    >
      <div className="p-8 flex justify-center items-center">
        <motion.span 
          className="text-6xl"
          variants={emojiVariants}
        >
          {emoji}
        </motion.span>
      </div>
      <div className="p-4 bg-white">
        <h3 className="text-xl font-bold mb-2 text-indigo-700">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
      <div className="p-4 bg-gray-50">
        <motion.button
          onClick={handleReadMore}
          className="w-full bg-indigo-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          variants={buttonVariants}
          whileHover="hover"
          whileTap={{ scale: 0.95 }}
        >
          Read More
        </motion.button>
      </div>
    </motion.div>
  );
};

const NonITCourses = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const titleVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    }
  };

  return (
    <div className="container mx-auto px-4 py-12 bg-gradient-to-br from-gray-100 to-indigo-100">
      <motion.h1
        className="text-4xl font-bold text-center mb-12 text-indigo-800"
        variants={titleVariants}
        initial="hidden"
        animate="visible"
      >
        Non-IT Training Courses
      </motion.h1>
      <motion.h2
        className="text-2xl font-semibold text-center mb-8 text-indigo-600"
        variants={titleVariants}
        initial="hidden"
        animate="visible"
      >
        100% Placement Assured in Non-IT Roles
      </motion.h2>
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {courses.map((course) => (
          <CourseCard key={course.id} {...course} />
        ))}
      </motion.div>
    </div>
  );
};

export default NonITCourses;