// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import AboutPage from './Components/AboutPage';
// import ServicesPage from './Components/ServicesPage';
 import MorePage from './Components/MorePage';
import ContactPage from './Components/ContactUs';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutPage />} />
       
        
        <Route path="/more" element={<MorePage />} />

        <Route path="/contact-us" element={<ContactPage />} /> 
      
        
        {/* Add other routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;
