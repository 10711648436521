import React from 'react';
import { motion } from 'framer-motion';
import { BookOpen, Briefcase, UserCheck } from 'lucide-react';

const WhatWeOffer = () => {
  return (
    <div className="bg-gradient-to-b from-white to-gray-100 py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <motion.h2
          className="text-4xl md:text-5xl font-extrabold text-center mb-8 text-gray-900"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          What We Offer
        </motion.h2>
        
        <motion.p
          className="text-xl text-center text-gray-600 mb-16 max-w-3xl mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          Our goal is to empower students with industry-relevant skills and ensure placement in top companies. Explore the benefits of joining our training programs:
        </motion.p>

        {/* Offerings */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <OfferingCard
            icon={<BookOpen size={48} />}
            title="Comprehensive Training"
            description="Learn from expert trainers with real-world experience in various domains, ensuring you're industry-ready with the latest skills."
            delay={0.3}
          />
          <OfferingCard
            icon={<Briefcase size={48} />}
            title="Guaranteed Placement"
            description="We collaborate with leading companies to ensure our students secure job offers from top organizations across various industries."
            delay={0.5}
          />
          <OfferingCard
            icon={<UserCheck size={48} />}
            title="Career Support"
            description="Our dedicated career support team provides guidance, mock interviews, and resume building to help you stand out in the job market."
            delay={0.7}
          />
        </div>

        {/* Logos of Top Companies */}
       
       
      </div>
    </div>
  );
};

const OfferingCard = ({ icon, title, description, delay }) => (
  <motion.div
    className="flex flex-col items-center text-center p-8 bg-white shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, delay }}
  >
    <div className="text-blue-600 mb-6">{icon}</div>
    <h3 className="text-2xl font-semibold mb-4 text-gray-900">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

export default WhatWeOffer;