import React, { useEffect } from 'react';
import { fadeInUp, fadeInLeft, fadeInRight } from './animations';
import Header from './Header';
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';

import Footer from './Footer';
import Technologies from './Technologies';
import ChooseUs from './ChooseUs';

const Home = () => {
  useEffect(() => {
    fadeInUp();
    fadeInLeft();
    fadeInRight();
  }, []);

  return (
    <div className="font-sans">
      <Header />
      <main className="container mx-auto px-4 pt-20">
        <HeroSection />
        <AboutSection />
        
        <Technologies/>
        < ChooseUs/>
      
      </main>
      <Footer />
    </div>
  );
};

export default Home;
