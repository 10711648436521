import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory

const roadmapVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.3,
      duration: 0.8,
      type: 'spring',
      stiffness: 80,
    },
  }),
};

const Roadmap = () => {
  const navigate = useNavigate(); // Hook to navigate to a different page

  const handleJoinNowClick = () => {
    navigate('/contact-us'); // Navigates to Contact Us page
  };

  return (
    <div className="relative bg-gray-300 py-16 px-8 md:px-16">
      <div className="max-w-7xl mx-auto">
        {/* Section Header */}
        <motion.h2
          className="text-4xl font-extrabold text-center mb-12 text-black tracking-wide"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Your Roadmap to a Rewarding Career
        </motion.h2>

        <motion.p
          className="text-lg text-center text-black mb-12 max-w-xl mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          Join our hands-on training program for ₹25,000, complete a one-month training, and land a job with a minimum salary of ₹15,000 at top companies. Your journey to success starts here!
        </motion.p>

        {/* Roadmap Steps */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* Step 1 */}
          <motion.div
            className="flex flex-col items-center text-center p-8 bg-white shadow-lg rounded-xl hover:shadow-2xl transform hover:scale-105 transition-all duration-500"
            custom={0}
            initial="hidden"
            animate="visible"
            variants={roadmapVariants}
          >
            <div className="w-16 h-16 flex justify-center items-center bg-orange-500 rounded-full mb-4 text-white text-xl font-bold">
              1
            </div>
            <h3 className="text-2xl font-semibold mb-4">Enroll for Training</h3>
            <p className="text-gray-600">
              Start your career by enrolling in our intensive, skill-focused program for ₹25,000, tailored to get you industry-ready.
            </p>
          </motion.div>

          {/* Step 2 */}
          <motion.div
            className="flex flex-col items-center text-center p-8 bg-white shadow-lg rounded-xl hover:shadow-2xl transform hover:scale-105 transition-all duration-500"
            custom={1}
            initial="hidden"
            animate="visible"
            variants={roadmapVariants}
          >
            <div className="w-16 h-16 flex justify-center items-center bg-blue-800 rounded-full mb-4 text-white text-xl font-bold">
              2
            </div>
            <h3 className="text-2xl font-semibold mb-4">Complete 1-Month Training</h3>
            <p className="text-gray-600">
              Undergo expert-led training, get hands-on experience, and be fully prepared to work in top companies.
            </p>
          </motion.div>

          {/* Step 3 */}
          <motion.div
            className="flex flex-col items-center text-center p-8 bg-white shadow-lg rounded-xl hover:shadow-2xl transform hover:scale-105 transition-all duration-500"
            custom={2}
            initial="hidden"
            animate="visible"
            variants={roadmapVariants}
          >
            <div className="w-16 h-16 flex justify-center items-center bg-orange-500 rounded-full mb-4 text-white text-xl font-bold">
              3
            </div>
            <h3 className="text-2xl font-semibold mb-4">Land a Job</h3>
            <p className="text-gray-600">
              After completing the training, get placed in top companies with a minimum salary of ₹15,000.
            </p>
          </motion.div>
        </div>

        {/* Animated Progress Bar */}
        <motion.div
          className="relative mt-16"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5, delay: 1 }}
        >
          <div className="absolute left-0 top-0 w-full h-1 bg-gradient-to-r from-orange-500 via-blue-800 to-orange-500"></div>

          {/* Progress Dots */}
          <motion.div
            className="absolute top-[-12px] left-[15%] w-4 h-4 bg-orange-500 rounded-full"
            animate={{ scale: [1, 1.4, 1], opacity: [1, 0.7, 1] }}
            transition={{ repeat: Infinity, duration: 2 }}
          ></motion.div>
          <motion.div
            className="absolute top-[-12px] left-[50%] w-4 h-4 bg-blue-800 rounded-full"
            animate={{ scale: [1, 1.4, 1], opacity: [1, 0.7, 1] }}
            transition={{ repeat: Infinity, duration: 2, delay: 1 }}
          ></motion.div>
          <motion.div
            className="absolute top-[-12px] left-[85%] w-4 h-4 bg-orange-500 rounded-full"
            animate={{ scale: [1, 1.4, 1], opacity: [1, 0.7, 1] }}
            transition={{ repeat: Infinity, duration: 2, delay: 2 }}
          ></motion.div>
        </motion.div>

        {/* CTA Button */}
        <motion.div
          className="text-center mt-12"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.5, delay: 1.3 }}
        >
          <button
            onClick={handleJoinNowClick}
            className="bg-orange-500 text-white py-3 px-8 rounded-full font-semibold hover:bg-orange-600 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105"
          >
            Join Now and Start Your Journey
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default Roadmap;
