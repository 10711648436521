import React from 'react';
import { motion } from 'framer-motion';

// Example icon URLs - replace with your own icons or images
const icons = {
  expert: "https://img.icons8.com/ios-filled/50/4A90E2/skills.png",
  innovation: "https://img.icons8.com/ios-filled/50/4A90E2/idea.png",
  satisfaction: "https://img.icons8.com/ios-filled/50/4A90E2/satisfaction.png",
  pricing: "https://img.icons8.com/ios-filled/50/4A90E2/price-tag.png"
};

const reasons = [
  {
    id: 1,
    title: "Expert Team",
    description: "Our team consists of highly skilled professionals with extensive experience in their respective fields.",
    icon: icons.expert
  },
  {
    id: 2,
    title: "Innovative Solutions",
    description: "We offer cutting-edge solutions that are tailored to meet your unique needs and drive your business forward.",
    icon: icons.innovation
  },
  {
    id: 3,
    title: "Customer Satisfaction",
    description: "We prioritize our clients and strive to exceed their expectations with every project.",
    icon: icons.satisfaction
  },
  {
    id: 4,
    title: "Affordable Pricing",
    description: "Our services are competitively priced, offering you the best value for your investment.",
    icon: icons.pricing
  }
];

const WhyChooseUs = () => {
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.3,
        duration: 0.8
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20
      }
    }
  };

  return (
    <div className="bg-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto text-center">
        <motion.h2
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="text-3xl font-bold text-gray-900 mb-12"
        >
          Why Choose Us?
        </motion.h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {reasons.map((reason) => (
            <motion.div
              key={reason.id}
              variants={itemVariants}
              className="bg-gray-100 p-6 rounded-lg shadow-lg text-center"
            >
              <img src={reason.icon} alt={reason.title} className="w-16 h-16 mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-gray-800 mb-2">{reason.title}</h3>
              <p className="text-gray-600">{reason.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
