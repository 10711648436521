import React from 'react';
import { motion } from 'framer-motion';

const AboutUsHeader = () => {
  return (
    <div className="relative w-full h-[50vh] overflow-hidden bg-gray-900">
      {/* Background Gradient */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-purple-600 opacity-80"></div>

      {/* Decorative Circles */}
      <motion.div
        className="absolute -top-10 -left-10 w-56 h-56 bg-pink-400 rounded-full opacity-40"
        animate={{ scale: [1, 1.5, 1] }}
        transition={{ duration: 6, repeat: Infinity }}
      ></motion.div>
      <motion.div
        className="absolute bottom-10 right-10 w-40 h-40 bg-green-400 rounded-full opacity-30"
        animate={{ scale: [1, 1.3, 1] }}
        transition={{ duration: 6, repeat: Infinity }}
      ></motion.div>

      {/* Content */}
      <div className="relative z-10 h-full flex flex-col justify-center items-center text-center px-8">
        <motion.div
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="bg-black bg-opacity-50 text-white p-8 rounded-lg shadow-lg max-w-2xl"
        >
          <h1 className="text-5xl font-extrabold mb-4 tracking-wide">Training & Placements</h1>
          <p className="text-lg font-light mb-6">
            At our institute, we are committed to shaping the future of our students by providing comprehensive training
            programs designed for the modern job market. With industry-aligned courses and a proven placement record, we ensure
            that our students are equipped with the skills they need to succeed in their chosen careers.
          </p>
          <button className="bg-pink-500 text-white py-3 px-6 rounded-full font-semibold hover:bg-pink-600 transition">
            Learn More
          </button>
        </motion.div>
      </div>

      {/* Animated Border Element */}
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        transition={{ duration: 1.5, delay: 0.8 }}
        className="absolute bottom-0 left-0 h-2 bg-blue-400"
      ></motion.div>
    </div>
  );
};

export default AboutUsHeader;
