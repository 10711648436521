import React from 'react';
import { Twitter, Instagram, Facebook, Linkedin, MapPin, Phone, Mail } from 'lucide-react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white p-4 md:p-8 lg:p-12">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {/* About Section */}
        <div>
          <h2 className="text-xl font-bold mb-4 text-blue-300">LEARNANDGAB</h2>
          <p className="text-sm md:text-base text-gray-300 mb-4">
            AT LEARNANDGRB, OUR MISSION IS TO EMPOWER INDIVIDUALS WITH SKILLS AND KNOWLEDGE FOR SUCCESSFUL CAREERS. WE PROVIDE TOP-QUALITY NON-IT TRAINING AND PLACEMENT SERVICES TO BRIDGE THE GAP BETWEEN EDUCATION AND EMPLOYMENT.
          </p>
          <div className="flex space-x-4 mt-4">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-blue-300 hover:text-blue-500">
              <Twitter size={24} />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-blue-300 hover:text-blue-500">
              <Instagram size={24} />
            </a>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-blue-300 hover:text-blue-500">
              <Facebook size={24} />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-blue-300 hover:text-blue-500">
              <Linkedin size={24} />
            </a>
          </div>
        </div>

        {/* Services Section */}
        <div className="flex flex-col md:flex-row md:justify-center md:space-x-8 lg:space-x-12">
          <div className="mb-4 md:mb-0">
            <h3 className="text-lg font-semibold mb-4 text-blue-300">OUR SERVICES</h3>
            <div className="space-y-2">
              <Link to="/contact-us" className="inline-block hover:underline text-gray-300 hover:text-blue-300">SKILL DEVELOPMENT TRAINING</Link>
              <Link to="/contact-us" className="inline-block hover:underline text-gray-300 hover:text-blue-300">JOB PLACEMENT SERVICES</Link>
              <Link to="/contact-us" className="inline-block hover:underline text-gray-300 hover:text-blue-300">CAREER COUNSELING</Link>
            </div>
          </div>
        </div>

        {/* Quick Links Section */}
        <div>
          <h3 className="text-lg font-semibold mb-4 text-blue-300">QUICK LINKS</h3>
          <div className="flex flex-col space-y-2">
            <Link to="/" className="hover:underline text-gray-300 hover:text-blue-300">Home</Link>
            <Link to="/about" className="hover:underline text-gray-300 hover:text-blue-300">Traning And Placement</Link>

            <Link to="/contact-us" className="hover:underline text-gray-300 hover:text-blue-300">Contact Us</Link>
          </div>
        </div>

        {/* Contact Info Section */}
        <div>
          <h3 className="text-lg font-semibold mb-4 text-blue-300">CONTACT INFO</h3>
          <div className="flex flex-col space-y-3">
            <div className="flex items-center text-gray-300">
              <MapPin size={20} className="mr-2 text-blue-300" />
              <p className="text-sm md:text-base">HYDERABAD, TELANGANA, INDIA</p>
            </div>
            <div className="flex items-center text-gray-300">
              <Phone size={20} className="mr-2 text-blue-300" />
              <p className="text-sm md:text-base">+918885554298</p>
            </div>
            <div className="flex items-center text-gray-300">
              <Mail size={20} className="mr-2 text-blue-300" />
              <p className="text-sm md:text-base lowercase">info@learnandgrab.com</p>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-700 mt-8 pt-4 text-center text-gray-400">
        <p className="text-sm mb-2">COPYRIGHT © 2024 LEARNANDGRB - ALL RIGHTS RESERVED.</p>
        <Link to="/terms" className="text-sm hover:underline hover:text-blue-300">TERMS & CONDITIONS</Link>
      </div>
    </footer>
  );
};

export default Footer;