import React, { useState } from 'react';
import { X } from 'lucide-react';
import { Link } from 'react-router-dom';
import logo from '../1725766521888oksh3rpy (4) (1).png'; // Adjust the filename if necessary

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const links = [
    { name: 'Home', path: '/' },
    { name: 'Training Programs', path: '/about' },
    { name: 'Contact Us', path: '/contact-us' }
  ];
  
  return (
    <header className="flex justify-between items-center p-4 bg-white fixed top-0 left-0 right-0 z-50 shadow-md">
      <div className="flex items-center space-x-2">
        <img src={logo} alt="Learnandgrab Logo" className="w-10 h-10 object-contain" />
        <h1 className="text-xl font-bold text-[#1c2e4a]">Learnandgrab</h1>
      </div>
      <nav className="hidden md:flex space-x-4">
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.path}
            className={`flex items-center text-gray-600 hover:text-[#f8a227] transition-colors duration-300 ${link.name === 'Training Programs' ? 'text-[#f8a227]' : ''}`}
          >
            {link.name}
          </Link>
        ))}
      </nav>
      <div className="md:hidden">
        <button
          className="text-gray-600 hover:text-[#f8a227]"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          Menu
        </button>
      </div>
      {isMenuOpen && (
        <div className="fixed inset-0 bg-white z-20 flex flex-col items-end p-4 space-y-4 pt-16 overflow-y-auto">
          <button
            className="absolute top-4 right-4 p-2 text-gray-600 hover:text-[#f8a227]"
            onClick={() => setIsMenuOpen(false)}
          >
            <X size={24} />
          </button>
          {links.map((link, index) => (
            <div key={index} className="w-full">
              <Link
                to={link.path}
                className={`block text-gray-600 hover:text-[#f8a227] transition-colors duration-300 text-lg ${link.name === 'Contact Us' ? 'text-[#f8a227]' : ''}`}
                onClick={() => setIsMenuOpen(false)}
              >
                {link.name}
              </Link>
            </div>
          ))}
        </div>
      )}
    </header>
  );
};

export default Header;