import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { ArrowRight, Book, Users, Lightbulb } from 'lucide-react';

const HeroSection = ({ bgColor = '#ffffff', gradientColors = 'rgba(0, 0, 128, 0.5), rgba(255, 165, 0, 0.5)' }) => {
  const [displayText, setDisplayText] = useState("Welcome to Learnandgrab");
  const controls = useAnimation();

  const phrases = [
    "Empowering Non-IT Professionals",
    "Bridging the Technology Gap",
    "Innovative Learning Solutions"
  ];

  useEffect(() => {
    let currentIndex = 0;
    const changeText = () => {
      setDisplayText(phrases[currentIndex]);
      currentIndex = (currentIndex + 1) % phrases.length;
    };

    const timer = setInterval(changeText, 3000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    controls.start(i => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.3 }
    }));
  }, [controls]);

  return (
    <div 
      className="relative h-screen flex flex-col justify-center items-center text-center overflow-hidden"
      style={{ background: `linear-gradient(to bottom right, ${gradientColors}), ${bgColor}` }}
    >
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="z-10 text-blue-900 text-3xl md:text-5xl font-bold mb-8 max-w-3xl px-4"
      >
        {displayText}
      </motion.div>
      
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5 }}
        className="z-10 text-gray-800 text-xl md:text-2xl mb-12 max-w-2xl px-4"
      >
        Accelerate your career with cutting-edge technology skills tailored for non-IT professionals.
      </motion.p>

      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="z-10 px-8 py-4 text-lg bg-orange-600 text-white rounded-full shadow-lg flex items-center transition-colors duration-300 hover:bg-orange-700"
      >
        Start Learning Now
        <ArrowRight className="ml-2" size={20} />
      </motion.button>

      <div className="flex justify-center mt-16 space-x-8">
        {[ 
          { icon: Book, text: "Expert-Led Courses" },
          { icon: Users, text: "Community Support" },
          { icon: Lightbulb, text: "Practical Projects" }
        ].map((item, index) => (
          <motion.div
            key={index}
            custom={index}
            initial={{ opacity: 0, y: 50 }}
            animate={controls}
            className="flex flex-col items-center"
          >
            <item.icon size={40} className="text-blue-900 mb-2" />
            <span className="text-sm font-medium text-gray-800">{item.text}</span>
          </motion.div>
        ))}
      </div>

      <motion.div
        className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-white to-transparent"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.5, duration: 1 }}
      />
    </div>
  );
};

export default HeroSection;
